@import 'variables';
@import '~bootstrap/scss/bootstrap.scss';

html {
  padding: 0;
  margin: 0;
}

body {
  background-color: $background-color;
  margin: 0;
  color: $font-color;
  font-family: Arial, sans-serif;
}

a {
  color: $primary-color;
  text-decoration: none;

  &:hover {
    color: $secondary-color;
  }
}

button {
  background-color: $primary-color;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: $secondary-color;
  }
}

.header
{
  height: 110px;
  padding: 1rem 0;
  background-color: #2f4f4f;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo img {
  width: 120px;
  height: auto;
}

.nav-menu ul {
  list-style-type: none;
  display: flex;
  gap: 1rem;
  margin: 0;
}

.nav-menu ul li a {
  text-decoration: none;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

.nav-menu ul li a:hover {
  color: #98FB98; /* Verde claro ao passar o mouse */
}
  